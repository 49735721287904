@import "utilities/variables.module";

// login page animation
.hydra-container {
  font-family:
    SFMono-Regular,
    Consolas,
    Liberation Mono,
    Menlo,
    Courier,
    monospace;
  font-size: 3vw;
  font-weight: 100;
  text-shadow: 0 0 5px black;
  background-color: #000 !important;
  background-image: url(../images/noise.png) !important;
  background-size: 100px;
  color: $hydra_main_color;
  text-align: center;
  margin: 0;
  height: 100vh;
  display: grid;
  overflow: hidden;

  p.hydra-logo {
    margin-top: -5px;
  }

  .hydra-logo {
    height: 110px;
    margin-bottom: 20px;
    margin-top: 15px;

    img:nth-child(2) {
      margin-left: 10px;
    }
  }

  .ant-form {
    .ant-input {
      background-color: #000;
      border-color: $hydra_main_color !important;
      color: #fff;
      border-radius: 0;
    }

    .ant-form-item {
      margin-bottom: 10px;
      // margin-top: 44px;
    }

    .ant-form-item:last-child {
      margin-bottom: 0;
      margin-top: 29px;
    }

    .ant-btn {
      border-radius: 0;
      color: #000;
      border-color: $mantis;
    }

    .ant-btn:hover {
      span {
        color: #000 !important;
      }

      background-color: #fff !important;
    }

    .ant-btn:focus {
      span {
        color: #000 !important;
      }
    }
  }

  .hydra {
    box-shadow: 0 0 2px red, inset 0 0 2px green;
    padding: 2vw;
    border: 0.2vw solid $hydra_main_color;
    grid-row: 2/2;
    grid-column: 2/2;
  }

  .terminal {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-gap: 1vw;
    padding: 3vw;
    grid-template-rows: 0.5fr auto 1fr;
    grid-template-columns: 1fr auto 1fr;
  }

  .glitch {
    animation: glitch 1.5s linear infinite;
  }

  .glitch--clone {
    opacity: 0.2;
  }

  .glitch--clone .hydra {
    filter: blur(2px);
    opacity: 0.8;
  }

  .glitch--top {
    animation: glitch--top 1s linear infinite;
  }

  .glitch--top .hydra {
    transform: translate(4vw, 4vw);
  }

  .glitch--bottom {
    animation: glitch--bottom 0.75s linear infinite;
  }

  .glitch--bottom .hydra {
    transform: translate(-1vw, -1vw);
  }

  .scanline {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(20, 255, 255, 0.03);
    animation: scanline 6s linear infinite;
  }

  .loading-bar--unloaded {
    color: #fff;
    text-shadow: 0 0 5px #fff;
  }

  .hidden {
    display: none;
  }

  p {
    font-family:
      SFMono-Regular,
      Consolas,
      Liberation Mono,
      Menlo,
      Courier,
      monospace;
    font-size: inherit;
    line-height: 1.5;
    margin: 0;
  }

  .text--sm {
    font-size: 1.75vw;
  }

  * + .text--sm {
    padding-top: 0.5vw;
  }

  * + .loading-bar {
    padding-top: 1vw;
  }

  .hydra-font {
    font-size: 2px;
  }

  @keyframes scanline {
    0.01% {
      transform: translateY(-100%);
    }

    99.99% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes glitch {
    2%,
    64% {
      transform: translate(2px, 0) skew(0deg);
    }

    4%,
    60% {
      transform: translate(-2px, 0) skew(0deg);
    }

    62% {
      transform: translate(0, 0) skew(5deg);
    }
  }

  @keyframes glitch--top {
    2%,
    64% {
      transform: translate(2px, -2px);
    }

    4%,
    60% {
      transform: translate(-2px, 2px);
    }

    62% {
      transform: translate(13px, -1px) skew(-13deg);
    }
  }

  @keyframes glitch--bottom {
    2%,
    64% {
      transform: translate(-2px, 0);
    }

    4%,
    60% {
      transform: translate(-2px, 0);
    }

    62% {
      transform: translate(-22px, 5px) skew(21deg);
    }
  }
}

.login-title {
  font-size: 18px;
  padding-top: 10px;
}

.login-subtitle {
  font-size: 14px;
  margin-bottom: 8px;
}

.login-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 24px;
  color: $mantis;
}
