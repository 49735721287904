$red: #e4572e;  //red
$red2: #cf4e50;  //red
$yellow: #eae8ff; //#E8C547; //white
$yellow2: #e8eb4b;
$yellow3: #e2aa00;
$blue: #4964de;
$yellow_rgb: rgba(234, 232, 255, 0.2); //rgba(232, 197, 71, 0.2);
$green: #40434e; //#45462A; // gray
$green2: #75b52c;
$gray: #222; //#343330;  // dark gray
$gray2: #8f948f;
$gray_rgb: rgba(34, 34, 34, 0.8); //rgba(52,51,48, 0.8);
$mantis: #73bf46;
$dark_hover: #292b32;//#2f3139;
$disabled_text: #9b9b9b;

$selected_nav_text_color: #40434e;

$stack_title_text_color: #eae8ff;


$diff: $yellow;
$difficulty_1: $diff;
$difficulty_2: $diff;
$difficulty_3: $diff;
$difficulty_4: $diff;
$difficulty_5: $diff;

$dark: $gray;
// $light: white;

$progress_0: #e4572e;
$progress_1: #d0502a;
$progress_2: #bb4826;
$progress_3: #a64022;
$progress_4: #92381e;
$progress_5: #7d301a;
$progress_6: #682815;
$progress_7: #532011;
$progress_8: #3f180d;
$progress_9: #2a1009;
$progress_10: #150805;

// $progress_0: #a3041a;
// $progress_1: #950418;
// $progress_2: #860416;
// $progress_3: #770313;
// $progress_4: #680311;
// $progress_5: #59030f;
// $progress_6: #4b020c;
// $progress_7: #3c020a;
// $progress_8: #2d0208;
// $progress_9: #1e0105;
// $progress_10: #0f0103;

$success: $mantis;
$alert: $yellow;
$danger: $red;
$fade_time: 1.5s;
$ant_modal_background: $gray_rgb;

// HD THEME
// $green_hd: rgba(115, 191, 70, 1); // (73 bf 46)
// $dark_green_hd: rgba(35, 60, 33, 1);
// $medium_green: rgba(65, 83, 60, 1);

// $pistacjowy: 196 224 178

// 116 192 74 => do pierwszego
// Gradient piorunów
// Gradient zaznaczonego baru
// Pogrubic strzalke

$theme_color: $mantis;//$yellow;

$nav: $gray;
$nav_bottom: $gray;

$borders: $mantis;//$yellow;
$borders2: $mantis;//$yellow;
$borders3: $mantis;//
$buttons_color: $mantis;

$backgrounds: $green;
$main_background: $green;
$background_padding: $green;
$modal_footers_background: $green;
$background_selected: $green;
$background_selected_2: $dark_hover;

$light_theme: $green;
$hoverable: $dark_hover; //$gray;//$mantis;//#393a23;
$active: $yellow;

$hover_color_table: $mantis;
$selected_texts: $yellow;//$yellow;
$texts: $yellow;//$mantis;//$yellow;
$texts_2: $yellow;//$mantis;//$yellow;
$texts_4: $yellow;// $mantis;
$texts_5: $yellow;//$mantis;
$hover_text: $mantis;
$selection: $yellow;
$selected_nav_texts: $mantis;

$progress_background: $mantis;
$progress_text_color: $yellow;
$presentation_color: $red;
$recruitation_color: $yellow2;
$hardware_color: #f497da;
$blueteam_color: #81adc8;

$hydra_main_color: #76b62a;

$map_network_line_color: $green2;
$map_label_1_color: $green2;
$map_label_2_color: $gray2;
$map_edge_color: $green2;
$map_network_header_subtitle_color: $yellow3;
$map_network_header_subtitle2_color: $red2;
$map_label_red_color: $red2;
$map_label_gray_color: $gray2;
$map_label_yellow_color: $yellow3;
$map_hover_color: $mantis;

// import local variables file to allow overwrite variables above
// this file is not watched by git
@import "./local_variables.scss";

:export {
  texts_2: $texts_2;
  map_network_line_color: $map_network_line_color;
  map_label_1_color: $map_label_1_color;
  map_label_2_color: $map_label_2_color;
  map_edge_color: $map_edge_color;
  map_network_header_subtitle_color: $map_network_header_subtitle_color;
  map_network_header_subtitle2_color: $map_network_header_subtitle2_color;
  map_label_red_color: $map_label_red_color;
  map_label_gray_color: $map_label_gray_color;
  map_label_yellow_color: $map_label_yellow_color;
  map_hover_color: $map_hover_color;
}
