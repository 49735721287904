@import "utilities/variables.module";

/* stylelint-disable no-descending-specificity */

body {
  .chart-img {
    width: 100%;
  }

  .pull-right {
    float: right;
  }

  .ant-upload.ant-upload-select {
    display: block;
  }

  .vis-manipulation {
    position: absolute;
    top: 0;
    left: 0;

    .vis-button {
      color: $yellow;
      background: $green;
      border-radius: 4px;
      border: 1px solid $mantis;
      line-height: 1.5715;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      cursor: pointer;
      touch-action: manipulation;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      margin: 0;
      margin-bottom: 5px;

      &:hover {
        color: $mantis;
      }
    }
  }

  .remove-all-ctf-flags {
    float: right;
  }

  .map-editor {
    text-align: left;

    button {
      margin-bottom: 5px;
    }

    border-right: 1px solid $mantis;
  }

  .map-properties-editor {
    padding-left: 20px !important;
  }

  .ant-select-clear {
    margin-top: -7px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .menu-top-left {
    float: left;
  }

  .challenge {
    border: 1px solid $yellow2;

    .ant-card-head {
      border: none;
    }

    &.challenge-done {
      border: 1px solid $mantis;
    }

    &.challenge-solve-expired {
      border: 1px solid gray;
    }
  }

  .challenge-files {
    text-align: center;
  }

  .challenge-modal {
    .ctf-flag {
      width: 100%;
    }
    .ant-btn[type="submit"] {
      width: 100%;
    }
    .ant-modal-body {
      max-height: 452px;
      padding: 0;

      .challenge-modal-body {
        height: 452px;

        .challenge-icon {
          margin-bottom: 10px;
          text-align: center;

          img {
            height: 22px;
            margin-top: -5px;
          }
        }

        .challenge-description {
          font-size: 16px;
        }
      }
    }

    .ant-modal-title {
      .anticon-check-circle {
        float: right;
        color: $mantis;
        font-size: 22px;
      }

      .challenge-solve-expired-icon {
        float: right;
        color: gray;
        height: 22px;
      }
    }
  }

  .flag-modal {
    .ant-modal-body {
      max-height: 452px;
      padding: 0;

      .flag-modal-body {
        height: 452px;
      }
    }
  }

  // [class*="-table"] {
  .quiz-table,
  .ranking-table,
  .settings-table {
    opacity: 0;

    th > div {
      text-align: left;
    }

    td {
      text-align: left;

      a {
        color: $yellow;

        &:hover {
          color: $mantis !important;
        }
      }
    }

    .ant-empty-description {
      color: $yellow;
      font-weight: bold;
    }

    .delete-btn {
      color: $red;
    }
  }

  .flags-table {
    tbody > tr > td:nth-child(2) {
      word-break: break-all;
    }
  }

  .login-form {
    min-width: 250px;
    text-align: left;

    .ant-form-explain {
      font-size: 1em;
      margin: 4px 0;
      color: $red;
    }

    .field-label {
      margin-top: 8px;
      margin-bottom: 10px;
    }

    .register-link {
      margin-top: 10px;
    }

    .ant-btn {
      background: $backgrounds;
      box-shadow: 0;
      text-shadow: 0;
      color: $yellow;
      border: 2px solid $hover_text;

      &:hover {
        background: $buttons_color;
        color: $green;
      }
    }

    a {
      color: $buttons_color;
      font-size: 1rem;

      &:hover {
        color: $yellow;
      }
    }

    svg {
      color: $theme_color;
    }

    .login-link {
      margin-top: 27px;
    }

    div[role=alert] {
      padding-top: 10px;
    }
  }

  .file-upload-input-container {
    .ant-form-item-control-input {
      min-height: auto;
    }
  }

  .ant-table-pagination {
    .ant-pagination-item-active {
      background-color: $mantis;
      border-color: $mantis;

      > a {
        color: $green;
      }
    }
  }
  // .ant-form-item-label,
  // .ant-form-item-control-wrapper {
  //   display: inline-block;
  // }

  .vis-tooltip {
    position: absolute;
    background-color: #fff;
    color: #000;
    padding: 2px 5px;
  }

  .filter-buttons {
    // position: relative;
    // bottom: 52px;
    // margin-top: 20px;
    font-weight: bold;

    .ant-row.ant-form-item {
      margin-right: 0;
    }

    form {
      display: block;
      text-align: right;

      .ant-row {
        display: block;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: $mantis;
      border-top: 1px solid $mantis;
      border-right: 1px solid $mantis;
      border-bottom: 1px solid $mantis;
      color: $green;
    }
  }

  .ant-table-filter-selected > svg {
    color: $danger;
  }

  .ant-table-filter-dropdown-link {
    color: $yellow;
  }

  .ranking-table {
    overflow-x: auto;

    .ant-table-thead tr th:first-child {
      width: 40px;
    }

    .ant-table-tbody tr td:first-child {
      text-align: center;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: $hoverable;
  }

  .ant-table-thead {
    .scoreboard-static-cell {
      vertical-align: bottom;
    }

    .disabled-flag {
      border: 1px solid red;
      display: none;
    }

    .scoreboard-flag-cell {
      overflow: hidden !important;
    }

    .fixed-scoreboard-width {
      width: 70px;
    }

    tr:nth-child(1) {
      .scoreboard-flag-cell {
        border-bottom: 1px solid $mantis !important;
        text-align: center;
        padding: 5px;

        p {
          margin-bottom: 0;
        }
      }

      .scoreboard-flag-cell:not(:last-child) {
        border-right: 3px solid $mantis !important;
      }
    }

    tr:nth-child(2) {
      .scoreboard-flag-cell {
        writing-mode: vertical-rl;
        overflow: auto;
        padding: 10px;

        p {
          transform: rotate(180deg);
          margin: 0;
        }
      }

      .scoreboard-flag-cell:not(:last-child) {
        border-right: 1px dashed #828282;
      }

      .scoreboard-flag-cell-last:not(:last-child) {
        border-right: 3px solid $mantis !important;
      }

      th:nth-child(1) {
        border-left: 1px solid $mantis !important;
      }
    }
  }

  .safari {
    .ant-table {
      .ant-table-thead {
        tr:nth-child(1), tr:nth-child(2) {
          .scoreboard-flag-cell {
            p {
              transform: none;
            }
          }
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      &.scoreboard-flag-cell {
        width: 55px;
        text-align: center;
        padding: 0;

        &:nth-child(4) {
          border-left: 1px dashed #828282;
        }
      }

      &.scoreboard-flag-cell:not(:last-child) {
        border-right: 1px dashed #828282;
      }

      &.scoreboard-flag-cell-last:not(:last-child) {
        border-right: 3px solid $mantis !important;
      }
    }

    tr.ant-table-expanded-row > td {
      background: transparent;
    }
  }

  .ant-dropdown-menu-item-selected > span {
    color: $red !important;
  }

  .ant-select-item-option-active {
    .ant-tag {
      border-color: $yellow;
    }
  }

  .ant-select-item-option-content,
  .ant-form-item-control-input-content,
  .ant-table-cell,
  .stack-title {
    .ant-tag {
      background: transparent;
    }
  }

  .stack-action {
    position: absolute;
    top: -3px;

    &.stack-action-off,
    &.stack-action-on:hover {
      color: $texts_2 !important;
      border-color: $texts_2 !important;
      cursor: pointer;
    }

    &.stack-action-disabled:hover {
      cursor: default;
      color: $mantis !important;
      border-color: $mantis !important;
    }

    // &.stack-action-on-en {
    //   &::before {
    //     content: "on";
    //   }
    //
    //   &:hover::before {
    //     content: "off";
    //   }
    // }
    //
    // &.stack-action-on-pl {
    //   &::before {
    //     content: "włącz";
    //   }
    //
    //   &:hover::before {
    //     content: "wyłącz";
    //   }
    // }

    &.stack-action-on,
    &.stack-action-off:hover {
      border-color: $mantis !important;
      color: $mantis !important;
    }

    // &.stack-action-off-en {
    //   &::before {
    //     content: "off";
    //   }
    //
    //   &:hover::before {
    //     content: "on";
    //   }
    // }
    //
    // &.stack-action-off-pl {
    //   &::before {
    //     content: "uśpione";
    //   }
    //
    //   &:hover::before {
    //     content: "włącz";
    //   }
    // }
  }

  .item-blocked {
    color: $red;

    .ant-collapse-header {
      color: $red !important;
    }
  }

  .collapse-blocked-item {
    margin-bottom: 20px;
  }

  .ant-collapse-content-box p:last-child {
    margin-bottom: 0;
  }

  .premium-version-only {
    color: $red;
    font-weight: bold;
    cursor: default;
    margin-bottom: 0;
  }

  .ant-modal-footer .premium-version-only {
    float: left;
    margin-top: 5px;
  }

  .header-name {
    // text-align: center;
    // display: inline;
  }

  .header-date {
    position: absolute;
    left: 20px;
  }

  .incorrect-attempts {
    margin-top: 20px;
    font-weight: bold;
    text-align: right;
  }

  .my-2 {
    margin: 20px 0;
    border: none;
    border-top: 1px solid $borders;
  }

  .danger {
    color: $red !important;
  }

  .success {
    color: $mantis !important;
  }

  .log-error {
    color: $red !important;
  }

  .modal-table {
    tr {
      cursor: pointer;

      th {
        font-weight: bold;
        text-align: center;
      }

      td {
        text-align: center;
      }

      &:last-child > td {
        border: none;
      }

      &:hover {
        // background: $gray;
        // color: $gray;
      }
    }
  }

  .console-buttons {
    position: fixed;
    right: 10px;
    top: 10px;

    button {
      margin-left: 10px;
    }
  }

  .form-footer {
    margin-bottom: 0;

    button {
      float: right;
      margin-left: 8px;
    }
  }

  .modal-desc {
    margin-top: 20px;

    .ant-input {
      // width: 382px;
    }

    .ant-form-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      margin-right: 0;

      button {
        float: right;
        width: 100%;
      }
    }
  }

  .bottom-logo {
    background-color: rgba(0, 0, 0, 0.15);
    // margin-top: 100px;
    // opacity: 0.1;
    img {
      height: 60px; // 44px;
      padding: 10px; //7px;
      opacity: 1;
    }
  }

  .swal2-input {
    background: $yellow;
  }

  i.anticon-down { color: $yellow; }

  .scenario-type-presentation {
    .ant-card-bordered,
    .ant-card-head,
    .ant-card-body,
    .ant-card-actions {
      border-color: $presentation_color;
    }

    .ant-tag {
      border-color: $presentation_color !important;
      color: $presentation_color !important;
    }
  }

  .scenario-type-recruit {
    .ant-card-bordered,
    .ant-card-head,
    .ant-card-body,
    .ant-card-actions {
      border-color: $recruitation_color;
    }

    .ant-tag {
      border-color: $recruitation_color !important;
      color: $recruitation_color !important;
    }
  }

  .scenario-type-hardware {
    .ant-card-bordered,
    .ant-card-head,
    .ant-card-body,
    .ant-card-actions {
      border-color: $hardware_color;
    }

    .ant-tag {
      border-color: $hardware_color !important;
      color: $hardware_color !important;
    }
  }

  .scenario-type-blueteam {
    .ant-card-bordered,
    .ant-card-head,
    .ant-card-body,
    .ant-card-actions {
      border-color: $blueteam_color;
    }

    .ant-tag {
      border-color: $blueteam_color !important;
      color: $blueteam_color !important;
    }
  }

  .ant-tooltip {
    .ant-tooltip-inner {
      color: $texts_4;
      background-color: $dark_hover;
    }

    .ant-tooltip-arrow {
      border-top-color: $dark_hover;
    }
  }

  .vm-action-disabled {
    color: $disabled_text !important;
    cursor: default;

    > span {
      color: $disabled_text !important;
    }
  }

  .console-dropdown-menu {
    &.ant-dropdown-menu {
      background-color: $dark_hover;
    }

    .ant-dropdown-menu-item:hover {
      background-color: $dark_hover;
    }
  }

  div.ant-layout-sider:not(.ant-layout-sider-collapsed) {
    // float: left;
    // margin-top: 12px;
    // display: none;

    ul.ant-menu li.ant-menu-item {
      text-align: left;
      // padding-left: 24px !important;
    }

    .ant-menu-submenu-title {
      text-align: left;
      // padding-left: 24px !important;
    }
  }

  div.ant-layout-sider-children > ul > li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
    padding-bottom: 20px;
  }

  textarea {
    resize: none;
  }

  .option-active {
    color: $yellow;

    &:hover {
      color: $mantis;
    }
  }

  .add-record-button {
    text-align: left;
    margin-bottom: 1rem;
    opacity: 0;
    // visibility: hidden;
  }

  .refresh-button {
    float: right;
  }

  .ant-btn[type*="submit"] {
    background: $mantis !important;
    color: $green;

    &:hover {
      span {
        color: $dark_hover !important;
      }

      border: 1px solid $yellow !important;
    }
  }

  mark {
    background: $yellow;
  }

  .active {
    color: $red !important;
  }

  .setting-cards {
    > div {
      padding-bottom: 4px;
      padding-top: 4px;
    }

    .ant-card {
      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4) !important;
        transition: all 0.2s !important;
      }

      .ant-card-head {
        padding: 30px;
        border-bottom: none;
      }
    }
  }

  // modal-table-quiz > modal-table, modal-desc {
  //   display: none;
  // }
  .d-none {
    display: none;
    visibility: hidden;
  }

  .quiz {
    .hoverable {
      height: 160px;
    }

    .solved-border > .ant-card {
      .ant-card-head {
        border-color: $mantis;
      }

      border-color: $mantis;
    }

    .progress-border > div.ant-card {
      .ant-card-head {
        border-color: $red;
      }

      border-color: $red;
    }

    .standard-border > div.ant-card {
      .ant-card-head {
        border-color: $yellow;
      }

      border-color: $yellow;
    }

    opacity: 0;
  }

  .ant-table-placeholder {
    display: none;
  }

  #path-left,
  #path-right {
    fill: $yellow !important;
  }

  #path-center {
    fill: $mantis !important;
  }
  // machines console
  #console-emulator {
    border: none;
    // width: 100%;
    // transform: scale(5);
    // overflow: hidden;
  }

  .console-window {
    overflow: hidden;
    height: 100vh;

    .loader-svg {
      svg {
        left: 0 !important;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .console-modal-functional-button {
    float: left;
  }

  .console-dropdown-link {
    margin-left: 10px !important;
  }

  .esxi-terminal {
    top: 20px;
    width: 1050px !important;

    .ant-modal-body {
      padding: 0 !important;
      height: 800px !important;
    }
  }

  .ant-form-item-label {
    text-align: left;
  }

  .text-404 {
    margin-top: 100px !important;
    text-align: center;
  }

  // .login-form {
  //   max-width: 300px;
  // }
  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;

    &:focus {
      color: $red;
      border: solid;
    }
  }

  .ant-menu-submenu {
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
      // background: $green; / konflikt z siderem
      color: $yellow !important;
    }

    .ant-menu-item-active {
      background: $mantis;
    }
  }

  .ant-pagination-item:not(.ant-pagination-item-active):focus,
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    border-color: $yellow !important;

    a {
      color: $yellow !important;
    }
  }

  .team-token:hover {
    color: $yellow !important;
  }

  .ant-pagination-next:hover:not(.ant-pagination-disabled),
  .ant-pagination-prev:hover:not(.ant-pagination-disabled) {
    .ant-pagination-item-link {
      color: $yellow;
      border-color: $yellow;
    }
  }

  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: $mantis !important;
      // background: $yellow;
      color: $yellow;

      &::after {
        border-color: $yellow;
      }
    }

    &::after {
      border: 1px solid $borders;
    }
  }

  .ant-checkbox.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border-color: $mantis !important;
    }
  }

  .ant-checkbox-inner {
    border-color: $mantis !important;
  }

  .swal2-popup .swal2-styled:focus {
    outline: none;
    // box-shadow: 0 0 0 1px $texts !important;
    box-shadow: 0 !important;
    border: 1px solid $texts !important;
  }

  .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
    background: $mantis;
    color: $green;
  }

  .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
    background: $mantis;
    color: $green;
    opacity: 0.5;
  }

  .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
    background: $mantis;
    opacity: 0.5;
  }

  .swal2-progresssteps .swal2-progressline {
    background: $mantis;
  }

  .swal2-progresssteps .swal2-progresscircle {
    color: $green;
    background: $mantis;
  }

  .ant-modal {
    .ant-btn {
      &:focus {
        box-shadow: 0 0 0 1px $mantis !important;
      }

      &:hover {
        color: $dark !important;
        background: $mantis;
        border: 1px solid $mantis;
      }
    }

    .ant-modal-footer {
      .ant-dropdown-link {
        color: $yellow;
        float: left;
        margin: 5px 0 0 10px;
      }
    }

    &.quick-guide-modal,
    &.instruction-modal {
      width: 60% !important;

      .ant-modal-body {
        max-height: 70vh !important;
        overflow-y: auto !important;
      }
    }

    &.quick-guide-modal,
    &.map-legend-modal {
      .ant-modal-body {
        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    &.done-modal {
      .ant-modal-content {
        border: none;
        background-color: transparent;
        box-shadow: none;
        text-align: center;

        .congrats {
          font-size: 40px;
          color: #ffa800;
        }

        .congrats2 {
          font-size: 20px;
          color: #ffa800;
        }

        img {
          animation-name: cup;
          animation-duration: 0.5s;
          animation-iteration-count: 2;

          @keyframes cup {
            0% {
              transform: scale(1);
            }

            50% {
              transform: scale(0.8);
            }

            100% {
              transform: scale(1);
            }
          }
        }

        .ant-modal-footer {
          border: none;
          text-align: center;

          > button {
            border: none;
          }
        }
      }
    }
  }

  *::selection {
    background: $selection; /* WebKit/Blink Browsers */
  }

  *::-moz-selection {
    background: $selection; /* Gecko Browsers */
  }

  .ant-progress-circle {
    .ant-progress-inner {
      background-color: $green !important;

      svg {
        path.ant-progress-circle-path {
          stroke: $danger !important;
        }

        path.ant-progress-circle-trail {
          stroke: $progress_background !important;
        }
      }
    }
  }

  .ant-modal-content {
    border: 1px solid $borders3;
  }

  .ant-modal-mask {
    background: $ant_modal_background !important;
  }

  .ant-notification-notice {
    border: 1px solid $borders3;
  }

  .ant-dropdown {
    .stack-switch {
      max-height: 80vh;
      overflow-y: auto;

      .status-on {
        border-color: $mantis !important;
        color: $mantis !important;
      }

      .stack-unavailable {
        font-style: italic;

        &:hover {
          cursor: default;

          span {
            color: $yellow !important;
          }
        }
      }

      .stack-current {
        font-weight: bold;

        &:hover {
          cursor: default;

          span {
            color: $yellow !important;
          }

          .status-on {
            color: $mantis !important;
          }
        }
      }

      .stack-name {
        margin-right: 10px;
      }
    }

    li.ant-dropdown-menu-item:hover {
      a,
      span {
        color: $hover_text !important;
      }

      .ant-tag:not(.status-on) {
        color: $yellow !important;
      }
    }
  }

  .ant-layout-sider-trigger {
    height: 60px !important;
    color: $mantis;
    background: $gray;

    &:hover {
      background: $mantis;
      color: $gray;
    }
  }

  // Reverse
  .swal2-container {
    .swal2-input {
      color: $dark;
    }

    .swal2-popup {
      border: 1px solid $borders3;
    }

    .swal2-content {
      color: $yellow;
    }

    h2 {
      color: $yellow !important;
    }

    color: $yellow !important;

    > .swal2-popup {
      background: $gray;
    }

    .swal2-icon {
      border-color: $borders3 !important;

      > span {
        color: $selected_nav_texts !important;
      }
    }

    .swal2-confirm {
      color: $gray !important;
      // background: $yellow !important;
      background-color: $mantis !important;
      border-left-color: $mantis !important;
      border-right-color: $mantis !important;
    }

    .swal2-cancel {
      color: $yellow !important;
      background: $red !important;
      background-color: $red !important;
      border-left-color: $red !important;
      border-right-color: $red !important;
    }

    .confirm_checkbox {
      margin-top: 20px;
    }
  }

  .ant-menu-horizontal {
    border-bottom: none !important;

    .ant-menu-item-selected span {
      color: $mantis;
    }

    .ant-menu-item-active button:not(:disabled):disabled span {
      color: $mantis;
    }

    .ant-menu-item,
    .ant-menu-submenu {
      // float: right;
    }
  }

  .ant-btn {
    &.quick-guide-button {
      color: #fff;
      background-color: $green;
      border-color: $red; //or #bb0a21;
      .anticon {
        margin-right: 5px;
      }

      &:hover {
        background-color: $mantis;
        border-color: $mantis;

        span {
          color: #fff !important;
        }
      }

      &:active {
        span {
          color: #fff !important;
        }
      }
    }

    &.instruction-button {
      .anticon {
        margin-right: 5px;
      }
    }
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    .quick-guide-button {
      span {
        color: #fff !important;
      }
    }
  }

  .ant-menu-submenu-arrow {
    &::after,
    &::before {
      background: $yellow !important;
    }
  }

  .ant-layout-sider-children {
    .admin-divider {
      //
      // @media(max-width: 768px) {
      //   display: none;
      // }
    }

    .ant-menu-item-selected {
      background: $selected_nav_texts !important;
    }

    .ant-menu-item-active > a {
      color: $hover_text !important;
    }

    .ant-menu-item-selected {
       & > a, & > a > span {
        color: $selected_nav_text_color !important;
      }
    }

    // &.ant-menu-item-selected {
    //   color: $dark !important;
    //   background: $mantis !important;
    //   linear-gradient(to right , #45462a,#343330)
    // }
  }

  .ant-menu-item-group-title {
    display: none;
  }

  // .submenu-title-wrapper {
  //   color: $texts_5 !important;
  // } // konflikt z headerem

  .user-settings-submenu {
    .ant-menu-item-active {
      color: $yellow;
      background: $green;

      &:hover {
        background: $mantis;

        p {
          color: $backgrounds;
        }
      }
    }
  }

  .bolts {
    margin: 2px 1px;
    font-size: 1.2rem !important;
  }

  .ant-progress-inner {
    background-color: $progress_background !important;
  }

  .body-padding {
    // padding: 24px;
    background: $background_padding;
    text-align: center;
    position: relative;
  }

  .ant-btn-danger {
    border-color: $borders3;
    background-color: $green;
    color: $texts_5;
    font-weight: bold;

    &:hover {
      color: $yellow !important;
    }
  }

  .ant-tag-green {
    border-color: $mantis !important;
    background-color: $green !important;
    color: $mantis !important;
    font-weight: bold;
  }

  .ant-tag-yellow {
    border-color: $yellow2 !important;
    background-color: $green !important;
    color: $yellow2 !important;
    font-weight: bold;
  }

  .ant-tag-red {
    border-color: $red !important;
    background-color: $green !important;
    color: $red !important;
    font-weight: bold;
  }

  .ant-tag-has-color {
    border-color: $yellow !important;
    background-color: $yellow !important;
    color: $gray !important;
    font-weight: bold;
  }

  .ant-tag-pink {
    border-color: $yellow !important;
    background-color: $yellow !important;
    color: $gray !important;
    font-weight: bold;
  }

  .ant-progress-bg[style*="width: 1"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 2"] {
    background: $progress_1 !important;
  }

  .ant-progress-bg[style*="width: 3"] {
    background: $progress_2 !important;
  }

  .ant-progress-bg[style*="width: 4"] {
    background: $progress_3 !important;
  }

  .ant-progress-bg[style*="width: 5"] {
    background: $progress_4 !important;
  }

  .ant-progress-bg[style*="width: 6"] {
    background: $progress_5 !important;
  }

  .ant-progress-bg[style*="width: 7"] {
    background: $progress_6 !important;
  }

  .ant-progress-bg[style*="width: 8"] {
    background: $progress_7 !important;
  }

  .ant-progress-bg[style*="width: 9"] {
    background: $progress_8 !important;
  }

  .ant-progress-bg[style*="width: 100"] {
    background: $progress_9 !important;
  }

  .ant-progress-bg[style*="width: 1%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 2%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 3%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 4%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 5%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 6%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 7%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 8%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 9%"] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 1."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 2."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 3."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 4."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 5."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 6."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 7."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 8."] {
    background: $progress_0 !important;
  }

  .ant-progress-bg[style*="width: 9."] {
    background: $progress_0 !important;
  }

  .ant-progress-text {
    color: $progress_text_color;
  }

  .ant-input {
    border-color: $borders !important;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $texts_5;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $texts_5;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $texts_5;
    }

    &:disabled {
      background-color: $dark_hover;
      color: #777272;
    }
  }

  .ant-input:focus {
    box-shadow: 0 0 0 1px $selected_texts !important;
  }

  .logo {
    background-image: url(../images/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    text-align: center;
    background-position: 50%;
    margin-bottom: 20px;
    margin-top: 100%;
  }

  .difficulty-1 {
    color: $difficulty_1 !important;
  }

  .difficulty-2 {
    color: $difficulty_2 !important;
  }

  .difficulty-3 {
    color: $difficulty_3 !important;
  }

  .difficulty-4 {
    color: $difficulty_4 !important;
  }

  .difficulty-5 {
    color: $difficulty_5 !important;
  }

  .scenarios {
    .scrollbar-container {
      &.ps:not(.scroll-tags-modal) {
        height: 55px;
      }
    }
  }

  .tags {
    text-align: left;
    margin-top: 10px;
    font-weight: bold;

    span {
      margin-right: 0.5rem;
    }
  }

  .tags-modal {
    margin-top: 30px;
  }

  .tags-displayer {
    display: inline;
  }

  .net-visualiser {
    text-align: center;

    .server-ico {
      cursor: pointer;
    }

    .status-poweredOn {
      color: $success;
    }

    .status-poweredOff {
      color: $dark;
    }

    .status-err {
      color: $danger;
    }

    .status-* {
      color: $alert;
    }
  }

  .ant-popover {
    max-width: 380px;
  }

  .ant-notification {
    .ant-notification-notice-description {
      word-break: break-word;
    }
  }

  .ant-notification.ant-notification-topRight {
    top: 66px !important;

    .ant-notification-notice-btn {
      > .ant-btn {
        box-shadow: none;
      }

      span {
        color: $mantis;
      }

      .ant-popover-open {
        box-shadow: none;

        span {
          color: $green !important;
        }
      }
      // border: 1px solid $mantis;
      // color: $mantis;
    }
  }

  .ant-modal-header {
    .ant-modal-title {
      font-weight: bold;
    }

    padding: 20px;
    text-align: center;

    .difficulty-display {
      display: inline;
      float: right;
      font-size: 1.2rem !important;
    }

    .header-title {
      font-weight: bold;
      font-size: 1.2rem !important;
    }

    .ant-tag {
      float: right;
      height: 1.5rem;
      font-size: 0.8rem;
      font-weight: bold;
    }
  }

  .ant-modal-body {
    canvas {
      width: 100%;
    }
  }

  .home {
    .title {
      text-align: center;
    }

    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 20px;
    }
  }

  .map-container {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 100%;
  }

  .map {
    opacity: 0;

    .vis-network {
      height: 74vh !important;
    }
  }

  .stack-title {
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
    position: relative;

    & > span {
      color: $stack_title_text_color;
      position: relative;

      .logo-stack-title {
        position: absolute;
        left: -50px;
        top: -50%;
        max-height: 45px;
      }
    }
  }

  .stack-action-button-map {
    position: absolute;
    right: 45px;
  }

  .ctf-flag {
    width: 400px;

    div[role=alert] {
      text-align: left;
      padding-top: 5px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-btn {
      width: 100%;
    }
  }

  .machines,
  .scenarios {
    opacity: 0;

    .ant-tag,
    .loader {
      float: right;
    }

    .loader {
      color: $theme_color;
    }

    .ant-card-body {
      h4 {
        // height: 100%;
      }
    }
  }

  .ctf-clock-navbar {
    position: fixed;
    bottom: 60px;
    width: 200px;
  }

  .ctf-clock-header {
    margin-top: 16px;

    h1 {

      &.ctf-finished {
        color: $mantis;
      }
      &.ctf-started {
        color: #f4b319;
      }
    }
  }

  .ant-layout-sider-collapsed {
    .ctf-clock-navbar {
      display: none;
    }
  }

  .react-spinner-loader-svg,
  .loader-svg {
    svg {
      position: absolute !important;
      top: 100px !important;
      left: 50% !important;
      margin-left: -25vw;
      width: 50vw !important;
      max-height: 50vh !important;

      polygon {
        stroke: $theme_color !important;
      }
    }
  }

  .fade-in {
    -webkit-transition: opacity $fade_time ease-in-out;
    -moz-transition: opacity $fade_time ease-in-out;
    -ms-transition: opacity $fade_time ease-in-out;
    -o-transition: opacity $fade_time ease-in-out;
    opacity: 1;
  }

  .fade-out {
    -webkit-transition: visibility 0s $fade_time, opacity calc($fade_time / 5) ease-in-out;
    -moz-transition: visibility 0s $fade_time, opacity calc($fade_time / 5) ease-in-out;
    -ms-transition: visibility 0s $fade_time, opacity calc($fade_time / 5) ease-in-out;
    -o-transition: visibility 0s $fade_time, opacity calc($fade_time / 5) ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  .resources-visualiser {
    margin-top: 20px;
    text-align: center;

    > div > div {
      margin: 10px 0;
    }

    .progress-title {
      font-weight: bold;

      > span {
        font-weight: normal;
      }
    }

    .progress-footer { margin-top: 10px; }
  }

  .anticon.anticon-smile {
    color: $success;
  }

  .anticon.anticon-frown {
    color: $danger;
  }

  .fa {
    color: $theme_color;
  }

  .ant-btn .fa {
    color: #fff;
    margin-right: 15px;
  }

  li > span > div > .fa {
    margin-right: 12px;
  }

  .ant-menu-dark .ant-menu-item > a {
    color: $texts_4;
  }

  .hoverable {
    cursor: pointer;

    &:hover {
      background: $hoverable;
    }
  }

  .hoverable-scenarios {
    @media (min-width: 992px) {
      height: 260px;

      > h4 {
        height: 160px;
      }
    }
  }

  .files-downloader:focus {
    text-decoration: none;
  }

  .files-downloader-yt {
    padding: 5px 10px;
  }

  .machines {
    .ant-card-body {
      .machine-card-body {
        .machine-card-description {
          text-align: left;
          padding: 24px;
        }

        .resources-visualiser {
          border-top: 1px solid $mantis;
          padding-left: 24px;
          padding-right: 24px;
        }
      }

      .machine-card-with-materials {
        .machine-card-materials {
          line-height: 46px;
        }

        .resources-visualiser {
          margin-top: 0;
        }

        .scrollbar-container {
          height: 238px;
        }
      }

      @media (min-width: 992px) {
        .machine-card-body {
          height: 350px;
        }
      }

      .scrollbar-container {
        height: 264px;
      }
    }
  }

  .map-modal {
    .ant-modal-body {
      a {
        color: $green2;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .ant-card-head-title {
      .header-title {
        font-weight: bold;
        margin: 0 !important;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }

    .ant-tag {
      float: right;
      height: 1.5rem;
      font-size: 0.8rem;
      font-weight: bold;
    }

    .ant-card-actions {
      height: 47px;

      > li:hover {
        > span > div {
          color: $hover_text;

          > span {
            color: $hover_text;
          }
        }
      }

      a,
      span {
        color: $texts_4;
      }
    }

    .ant-switch-checked {
      background-color: $theme_color !important;

      > span {
        color: $green !important;
      }
    }

    &.machine-unavailable-demo {
      .header-title,
      .ant-card-body {
        color: $red;
      }
    }
  }

  .map-legend-button {
    float: right;
    margin-right: 30px;
    z-index: 999;
  }

  .ant-layout {
    min-height: 100vh;

    .ant-layout-content {
      height: 100%;

      &.component_ranking {
        margin-top: 20px !important;
      }

      > div {
        height: 100%;

        .scenarios {
          .difficulty-display {
            float: right;
          }

          .header-title {
            // float: left;
          }
        }

        .ant-card-actions {
          height: 47px;

          > li:hover {
            > span > div {
              color: $hover_text;

              > span {
                color: $hover_text;
              }
            }

            > span > a {
              color: $hover_text !important;

              > span {
                color: $hover_text !important;
              }
            }
          }

          a,
          span {
            color: $texts_4;
          }
        }

        .ant-card-head {
          .header-title {
            font-weight: bold;
            margin: 0 !important;
            font-size: 1rem;
            text-transform: uppercase;
          }

          .anticon-minus-circle,
          .anticon-check-circle,
          .fa {
            float: left;
            font-size: 1.5em;
          }

          .anticon-minus-circle > svg {
            color: $danger;
          }

          .anticon-check-circle > svg {
            color: $success;
          }
        }

        .ant-card:not(.map-element-attributes):not(.challenge) {
          .ant-card-body {
            padding: 0 !important;

            > div {
              padding: 24px !important;
            }

            > div.machine-card-body {
              padding: 0 !important;
            }
          }
        }
      }
    }

    .challenge {
      &.ant-card {
        height: 200px;

        .ant-card-body {
          padding: 0 10px 0 10px;
        }
      }

      .challenge-name-container {
        height: 100px;
        position: relative;
      }

      .challenge-name {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
      }

      .challenge-solved-count {
        margin-top: 10px;
      }

      .challenge-icon {
        margin-top: 10px;
        margin-bottom: 10px;

        img {
          height: 22px;
        }
      }

      .challenge-done-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $mantis;
        font-size: 30px;
      }

      .challenge-solve-expired-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: gray;
        height: 25px;
      }
    }

    .challenge-category-name {
      font-size: 20px;
    }

    .challenge-category-head:not(:first-child) {
      margin-top: 30px;
    }
  }

  .ctf-clocks {
    text-align: center;
  }

  .ant-picker-cell, .ant-picker-header button {
    color: gray;
  }

  .ant-picker-cell-in-view {
    color: #eae8ff;
  }

  .ant-picker-cell-today {
    color: #000000;
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: $mantis !important;
    }
  }

  .ant-switch-checked {
    background-color: $theme_color !important;

    > span {
      color: $green !important;
    }
  }

  ::-webkit-input-placeholder {
    opacity: 0.6 !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    opacity: 0.6 !important;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    opacity: 0.6 !important;
  }

  :-ms-input-placeholder {
    opacity: 0.6 !important;
  }

  .custom-center {
    text-align: center;
    padding: 10px;
  }

  .ant-modal-mask[style*="z-index: 1001"] + div.ant-modal-wrap {
    .ant-modal-content {
      border-color: $presentation_color !important;

      .ant-modal-header,
      .ant-modal-body,
      .ant-modal-footer {
        border-color: $presentation_color !important;
      }

      span.fa-download,
      // button,
      div.ant-tag {
        color: $presentation_color !important;
        border-color: $presentation_color !important;
      }

      button {
        border-color: $presentation_color;

        &:active,
        &:hover {
          border-color: $presentation_color;
          background-color: $presentation_color;
        }

        &:focus {
          color: $presentation_color;
          box-shadow: none !important;
        }
      }

      .ant-divider {
        &::before,
        &::after {
          border-color: $presentation_color !important;
        }
      }

      .ant-popover-title {
        border-color: $presentation_color !important;
      }
    }
  }

  .user-quiz-details-table {
    margin-top: 20px;

    .ant-table-pagination {
      margin-bottom: 0;

      li {
        margin-right: 5px !important;
      }
    }
  }

  .user-quiz-details-collapse {
    .ant-collapse-item-disabled > div {
      color: $disabled_text !important;
    }
  }

  // make perfect scrollbar always visible and remove ugly background when scrolling
  .ps {
    .ps__rail-y:hover {
      background-color: transparent !important;
    }

    .ps__rail-y {
      opacity: 0.6 !important;

      &.ps--clicking {
        background-color: transparent !important;
      }
    }
  }

  .map-delete-element-btn {
    margin-top: 20px;
  }
}

// App scss
.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  // color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-7 17:3:39
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }

  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}

@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }

  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}

.rotate-scale-up {
  -webkit-animation: rotate-scale-up 0.65s linear both;
  animation: rotate-scale-up 0.65s linear both;
}

.fade-in-2 {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-7 17:7:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
// dynamic map
.hd-map {
  width: 100%;
  height: 90vh;
}

.vis-network {
  &:focus {
    outline: none;
  }
}

.vis-network canvas {
  width: 100%;

  &:focus {
    outline: none;
  }
}

// pdf file viewer
.pdf-viewer {
  nav {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .prev {
      margin-right: 20px;
    }
  }

  .footer {
    text-align: center;
    margin-top: 30px;
  }

  .react-pdf__Document {
    display: flex;
    justify-content: center;
  }

  ::selection {
    color: transparent;
  }

  ::-moz-selection {
    color: #000;
  }
}

.scenario-file-viewer {
  float: right;
  margin-left: 20px;
}

.register-logo,
.reset-password-logo {
  text-align: center;
  padding: 50px 0;
}

.scenario-block-dropdown {
  margin-right: 18px;

  & > .ant-btn:first-child {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  & > .ant-btn:last-child {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

.scroll-scenarios {
  height: 210px !important;

  &.with-tags {
    height: 160px !important;
  }
}

.scroll-scenarios-modal {
  max-height: 230px;
}

.quiz {
  .scrollbar-container {
    padding-right: 10px;
  }
}

.ranking-image {
  opacity: 0;
  position: absolute;
  left: 50px;
  top: 70px;
  max-width: 200px;
  max-height: 200px;
  z-index: 999;
}

.scoreboard-frozen {
  color: $mantis;
  border: 1px solid $mantis;
  width: 300px;
  padding: 20px;
  margin: 0 auto 35px auto;
}

.ant-picker-input {
  input {
    border: none;
  }
}

.scoreboard-group-parent {
   max-height: 52px;
   overflow: hidden;
}

.scoreboard-group-child {
   height: 52px;
   display: inline-block;
   text-align: center;

   &:nth-child(1) {
     img {
       max-height: 52px;
     }
   }

   &:nth-child(2) {
     margin-left: 5px;
   }
}

.view-selected, .view-selected:active {
  background: $mantis !important;
  color: $texts_2 !important;
}

.table-button-left {
  position: absolute;
  left: 350px;
  z-index: 999;
}

.table-button-right {
  position: absolute;
  right: 10px;
  z-index: 999;
}

.sketch-picker {
  input {
    color: #000;
  }
}

.user-points {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
}

.stack-menu-dropdown {
  &:hover {
    cursor: pointer;
  }
}

.admin-challenge-solve {
  margin-bottom: 15px;

  p {
    font-weight: bold;
  }
}

.ant-upload-list-item-info {
  &:hover {
    background-color: transparent !important;
  }
}

.file-remove-container {
  text-align: right;
  margin-bottom: 20px;
}

.external-link-icon {
  max-height: 14px;
  float: left;
}
