@import "utilities/variables.module";
/* stylelint-disable at-rule-empty-line-before,rule-empty-line-before,no-descending-specificity,no-duplicate-selectors,at-rule-no-unknown,selector-type-no-unknown */

body {
  color: $texts_2;
  background-color: $light_theme;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $texts_2;
}
a {
  color: $yellow;
}
a:hover {
  color: $buttons_color;
}
a:active {
  color: $active;
}
::-moz-selection {
  color: $light_theme;
  background: $backgrounds;
}
::selection {
  color: $light_theme;
  background: $backgrounds;
}
html {
  --antd-wave-shadow-color: $backgrounds;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  -webkit-box-shadow: 0 0 0 0 $backgrounds;
  box-shadow: 0 0 0 0 $backgrounds;
}
@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 $backgrounds;
    box-shadow: 0 0 0 $backgrounds;
  }
}
@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 $backgrounds;
    box-shadow: 0 0 0 $backgrounds;
  }
}
.ant-alert {
  color: $texts_2;
}
.ant-alert-info {
  background-color: $background_selected;
}
.ant-alert-info .ant-alert-icon {
  color: $backgrounds;
}
.ant-alert-error .ant-alert-icon {
  color: $danger;
}
.ant-alert-with-description {
  color: $texts_2;
}
.ant-alert-with-description .ant-alert-message {
  color: $texts_2;
}
.ant-alert-message {
  color: $texts_2;
}
.ant-anchor {
  color: $texts_2;
}
.ant-anchor-wrapper {
  background-color: $light_theme;
}
.ant-anchor-ink-ball {
  background-color: $light_theme;
  border: 2px solid $backgrounds;
}
.ant-anchor-link-title {
  color: $texts_2;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: $backgrounds;
}
.ant-select-auto-complete {
  color: $texts_2;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: $light_theme;
  border: 1px solid $borders2;
  border-radius: 4px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $buttons_color;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #292b32;
  color: #9b9b9b;
}
.ant-select-multiple .ant-select-selector {
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: $buttons_color;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: $dark_hover !important;
}
.ant-select-multiple .ant-select-selection-item {
  background: transparent;
  border: 1px solid $borders;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  border-color: $borders2;
}
.ant-select {
  color: $texts_2;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $buttons_color;
}
.ant-select-arrow {
  color: $yellow;
}
.ant-select-clear {
  background: $light_theme;
}
.ant-select-dropdown {
  color: $texts_2;
  background-color: $light_theme;
}
.ant-select-item-empty {
  color: $texts_2;
}
.ant-select-item {
  color: $texts_2;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $progress_background;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $texts_2;
  background-color: $background_selected;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: $backgrounds;
}
.ant-empty-img-default-ellipse {
  fill: $progress_background;
}
.ant-empty-img-default-g {
  fill: $light_theme;
}
.ant-empty-img-simple-ellipse {
  fill: $progress_background;
}
.ant-empty-img-simple-g {
  stroke: $borders2;
}
.ant-avatar {
  color: $texts_2;
  color: $light_theme;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid $light_theme;
}
.ant-popover {
  color: $texts_2;
}
.ant-popover-inner {
  background-color: $light_theme;
}
.ant-popover-title {
  color: $texts_2;
}
.ant-popover-inner-content {
  color: $texts_2;
}
.ant-popover-message {
  color: $texts_2;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-right-color: $light_theme;
  border-bottom-color: $light_theme;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: $light_theme;
  border-left-color: $light_theme;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: $light_theme;
  border-left-color: $light_theme;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: $light_theme;
  border-right-color: $light_theme;
}
.ant-back-top {
  color: $texts_2;
}
.ant-back-top-content {
  color: $light_theme;
}
.ant-back-top-content:hover {
  background-color: $texts_2;
}
.ant-badge {
  color: $texts_2;
}
.ant-badge-count {
  color: $light_theme;
  background: $danger;
  -webkit-box-shadow: 0 0 0 1px $light_theme;
  box-shadow: 0 0 0 1px $light_theme;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: $light_theme;
}
.ant-badge-dot {
  background: $danger;
  -webkit-box-shadow: 0 0 0 1px $light_theme;
  box-shadow: 0 0 0 1px $light_theme;
}
.ant-badge-status-processing {
  background-color: $backgrounds;
}
.ant-badge-status-processing::after {
  border: 1px solid $backgrounds;
}
.ant-badge-status-default {
  background-color: $borders2;
}
.ant-badge-status-error {
  background-color: $danger;
}
.ant-badge-status-blue {
  background: $backgrounds;
}
.ant-badge-status-text {
  color: $texts_2;
}
.ant-ribbon-color-blue {
  color: $backgrounds;
  background: $backgrounds;
}
.ant-breadcrumb {
  color: $texts_2;
}
.ant-breadcrumb a:hover {
  color: $buttons_color;
}
.ant-breadcrumb > span:last-child {
  color: $texts_2;
}
.ant-breadcrumb > span:last-child a {
  color: $texts_2;
}
.ant-menu-item-danger.ant-menu-item {
  color: $danger;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: $danger;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: $danger;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: $danger;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: $danger;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: $danger;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: $light_theme;
  background-color: $danger;
}
.ant-menu {
  color: $texts_2;
  background: $light_theme;
}
.ant-menu-submenu-selected {
  color: $backgrounds;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: $background_selected;
}
.ant-menu-item a {
  color: $texts;
}
.ant-menu-item a:hover {
  color: $backgrounds;
}
.ant-menu-item > .ant-badge a {
  color: $texts_2;
}
.ant-menu-item > .ant-badge a:hover {
  color: $backgrounds;
}
.ant-menu-item-divider {
  background-color: $borders;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $mantis;
}
.ant-menu-item-selected {
  color: $backgrounds;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $backgrounds;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $background_selected;
}
.ant-menu > .ant-menu-item-divider {
  background-color: $borders;
}
.ant-menu-submenu > .ant-menu {
  background-color: $light_theme;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: $light_theme;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from($backgrounds), to($backgrounds));
  background: linear-gradient(to right, $backgrounds, $backgrounds);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: $backgrounds;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  border-bottom: 2px solid $backgrounds;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: $texts_2;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: $backgrounds;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: $backgrounds;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $backgrounds;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: $selected_texts;
  background: $nav;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: $light_theme;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: $nav;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: $nav;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: $backgrounds;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: $selected_texts;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: $mantis;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: $light_theme;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $backgrounds;
}
.ant-menu-dark .ant-menu-item-selected {
  color: $light_theme;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: $light_theme;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: $light_theme;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: $light_theme;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: $backgrounds;
}
.ant-tooltip {
  color: $texts_2;
}
.ant-tooltip-inner {
  color: $light_theme;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: $backgrounds;
}
.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: $backgrounds;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: $danger;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: $light_theme;
  background-color: $danger;
}
.ant-dropdown {
  color: $texts_2;
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  border-right-color: $light_theme;
  border-bottom-color: $light_theme;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  border-top-color: $light_theme;
  border-left-color: $light_theme;
}
.ant-dropdown-menu {
  background-color: $light_theme;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: $texts_2;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: $texts_2;
}
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: $texts_2;
}
.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: $texts_2;
}
.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: $texts_2;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $backgrounds;
  background-color: $background_selected;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $background_selected;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  background-color: $light_theme;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  background-color: $light_theme;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: $backgrounds;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: $nav;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: $selected_texts;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: $selected_texts;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: $light_theme;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: $light_theme;
  background: $backgrounds;
}
.ant-btn {
  color: $texts_2;
  background: $light_theme;
  border-color: $borders2;
  border-radius: 4px;
}
.ant-btn:hover,
.ant-btn:focus {
  color: $buttons_color;
  background: $light_theme;
  border-color: $buttons_color;
}
.ant-btn:active {
  color: $active;
  background: $light_theme;
  border-color: $active;
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background: $dark_hover !important;
  color: $disabled_text;
  border-color: $borders2;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: $light_theme;
}
.ant-btn-primary {
  color: $texts;
  background: $backgrounds;
  border-color: $borders;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: $light_theme;
  background: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-primary:active {
  color: $light_theme;
  background: $active;
  border-color: $active;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: $buttons_color;
  border-left-color: $buttons_color;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: $borders2;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: $buttons_color;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: $borders2;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: $buttons_color;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: $borders2;
}
.ant-btn-ghost {
  color: $texts_2;
  border-color: $borders2;
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-ghost:active {
  color: $active;
  border-color: $active;
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-dashed {
  color: $texts_2;
  background: $light_theme;
  border-color: $borders2;
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: $buttons_color;
  background: $light_theme;
  border-color: $buttons_color;
}
.ant-btn-dashed:active {
  color: $active;
  background: $light_theme;
  border-color: $active;
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-danger {
  color: $light_theme;
  background: $danger;
  border-color: $danger;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: $light_theme;
}
.ant-btn-danger:active {
  color: $light_theme;
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-link {
  color: $texts;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-link:active {
  color: $active;
  border-color: $active;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-text {
  color: $texts_2;
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-text:active {
  color: $active;
  border-color: $active;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: $texts_2;
}
.ant-btn-text:active {
  color: $texts_2;
}
.ant-btn-dangerous {
  color: $danger;
  background: $light_theme;
  border-color: $danger;
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  background: $light_theme;
}
.ant-btn-dangerous:active {
  background: $light_theme;
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-dangerous.ant-btn-primary {
  color: $light_theme;
  background: $danger;
  border-color: $danger;
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: $light_theme;
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: $light_theme;
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-dangerous.ant-btn-link {
  color: $danger;
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-dangerous.ant-btn-link:active {
  color: $active;
  border-color: $active;
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-dangerous.ant-btn-text {
  color: $danger;
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-dangerous.ant-btn-text:active {
  color: $active;
  border-color: $active;
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn::before {
  background: $light_theme;
}
.ant-btn-background-ghost {
  color: $light_theme;
  border-color: $light_theme;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: $backgrounds;
  border-color: $backgrounds;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: $buttons_color;
  border-color: $buttons_color;
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: $active;
  border-color: $active;
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-background-ghost.ant-btn-danger {
  color: $danger;
  border-color: $danger;
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: $danger;
  border-color: $danger;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: $danger;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  background: $progress_background;
  border-color: $borders2;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: $buttons_color;
  border-left-color: $borders2;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: $borders2;
  border-left-color: $buttons_color;
}
.ant-picker-calendar {
  color: $texts_2;
  background: $light_theme;
}
.ant-picker-calendar .ant-picker-panel {
  background: $light_theme;
}
.ant-picker-calendar-full .ant-picker-panel {
  background: $light_theme;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: $progress_background;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: $background_selected;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: $backgrounds;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  color: $texts_2;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: $backgrounds;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: $texts_2;
}
.ant-radio-group {
  color: $texts_2;
}
.ant-radio-wrapper {
  color: $texts_2;
}
.ant-radio {
  color: $texts_2;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $backgrounds;
}
.ant-radio-checked::after {
  border: 1px solid $backgrounds;
}
.ant-radio-inner {
  background-color: $light_theme;
  border-color: $borders2;
}
.ant-radio-inner::after {
  background-color: $backgrounds;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $backgrounds;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: $progress_background;
  border-color: $borders2 !important;
}
.ant-radio-button-wrapper {
  color: $texts_2;
  background: $light_theme;
  border-top: 1px solid $borders2;
  border-bottom: 1px solid $borders2;
  border-right: 1px solid $borders2;
}
.ant-radio-button-wrapper a {
  color: $texts_2;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: $borders2;
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid $borders2;
}
.ant-radio-button-wrapper:hover {
  color: $yellow;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $backgrounds;
  background: $light_theme;
  border-color: $backgrounds;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $backgrounds;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $borders;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $backgrounds;
  border-color: $buttons_color;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $buttons_color;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: $active;
  border-color: $active;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: $active;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $light_theme;
  background: $backgrounds;
  border-color: $backgrounds;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $light_theme;
  background: $buttons_color;
  border-color: $buttons_color;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: $light_theme;
  background: $active;
  border-color: $active;
}
.ant-radio-button-wrapper-disabled {
  background-color: $progress_background;
  border-color: $borders2;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  background-color: $progress_background;
  border-color: $borders2;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: $borders2;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  border-color: $borders2;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid $borders2;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $buttons_color;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: $borders2;
}
.ant-picker-panel {
  background: $light_theme;
}
.ant-picker-panel-focused {
  border-color: $backgrounds;
}
.ant-picker-header {
  color: $texts_2;
}
.ant-picker-header > button:hover {
  color: $texts_2;
}
.ant-picker-header-view button:hover {
  color: $backgrounds;
}
.ant-picker-content th {
  color: $texts_2;
}
.ant-picker-cell-in-view {
  color: $texts_2;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: $progress_background;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $backgrounds;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: $background_selected;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: $light_theme;
  background: $backgrounds;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: $background_selected;
}
.ant-picker-cell-disabled::before {
  background: $progress_background;
}
.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
  background: $progress_background;
}
.ant-picker-today-btn {
  color: $backgrounds;
}
.ant-picker-today-btn:hover {
  color: $buttons_color;
}
.ant-picker-today-btn:active {
  color: $active;
}
.ant-picker-week-panel-row:hover td {
  background: $progress_background;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: $backgrounds;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $light_theme;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: $light_theme;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: $texts_2;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: $progress_background;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: $background_selected;
}
.ant-picker {
  color: $texts_2;
  background: $light_theme;
  border: 1px solid $borders2;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: $buttons_color;
}
.ant-picker-focused {
  border-color: $buttons_color;
}
.ant-picker.ant-picker-disabled {
  background: $progress_background;
  border-color: $borders2;
}
.ant-picker-input > input {
  color: $texts_2;
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-picker-input > input:hover {
  border-color: $buttons_color;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: $buttons_color;
}
.ant-picker-input > input-disabled {
  background-color: $progress_background;
}
.ant-picker-input > input-disabled:hover {
  border-color: $borders2;
}
.ant-picker-input > input[disabled] {
  background-color: $progress_background;
}
.ant-picker-input > input[disabled]:hover {
  border-color: $borders2;
}
.ant-picker-clear {
  background: $light_theme;
}
.ant-picker-range .ant-picker-active-bar {
  background: $backgrounds;
}
.ant-picker-dropdown {
  color: $texts_2;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: $backgrounds;
  background: $background_selected;
}
.ant-picker-panel-container {
  background: $light_theme;
}
.ant-tag {
  color: $texts_2;
  border: 1px solid $borders2;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: $texts_2;
}
.ant-tag-close-icon:hover {
  color: $texts_2;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: $light_theme;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: $backgrounds;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: $light_theme;
}
.ant-tag-checkable-checked {
  background-color: $backgrounds;
}
.ant-tag-checkable:active {
  background-color: $active;
}
.ant-tag-pink-inverse {
  color: $light_theme;
}
.ant-tag-magenta-inverse {
  color: $light_theme;
}
.ant-tag-red-inverse {
  color: $light_theme;
}
.ant-tag-volcano-inverse {
  color: $light_theme;
}
.ant-tag-orange-inverse {
  color: $light_theme;
}
.ant-tag-yellow-inverse {
  color: $light_theme;
}
.ant-tag-gold-inverse {
  color: $light_theme;
}
.ant-tag-cyan-inverse {
  color: $light_theme;
}
.ant-tag-lime-inverse {
  color: $light_theme;
}
.ant-tag-green-inverse {
  color: $light_theme;
}
.ant-tag-blue {
  color: $backgrounds;
  background: $background_selected;
}
.ant-tag-blue-inverse {
  color: $light_theme;
  background: $backgrounds;
  border-color: $backgrounds;
}
.ant-tag-geekblue-inverse {
  color: $light_theme;
}
.ant-tag-purple-inverse {
  color: $light_theme;
}
.ant-tag-processing {
  color: $backgrounds;
  background: $background_selected;
}
.ant-card {
  color: $texts_2;
  background: $light_theme;
}
.ant-card-bordered {
  border: 1px solid $borders;
}
.ant-card-head {
  color: $texts_2;
  border-bottom: 1px solid $borders;
}
.ant-card-head .ant-tabs {
  color: $texts_2;
}
.ant-card-extra {
  color: $texts_2;
}
.ant-card-actions {
  background: $light_theme;
  border-top: 1px solid $borders;
}
.ant-card-actions > li > span:hover {
  color: $backgrounds;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: $backgrounds;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid $borders;
}
.ant-card-meta-title {
  color: $texts_2;
}
.ant-tabs-dropdown {
  color: $texts_2;
}
.ant-tabs-dropdown-menu {
  background-color: $light_theme;
}
.ant-tabs-dropdown-menu-item {
  color: $texts_2;
}
.ant-tabs-dropdown-menu-item:hover {
  background: $progress_background;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: $backgrounds;
  background: $light_theme;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: $light_theme;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: $light_theme;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: $light_theme;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: $light_theme;
}
.ant-tabs {
  color: $texts_2;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: $buttons_color;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: $active;
}
.ant-tabs-ink-bar {
  background: $backgrounds;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: $active;
}
.ant-tabs-tab-remove:hover {
  color: $texts_2;
}
.ant-tabs-tab:hover {
  color: $buttons_color;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $backgrounds;
  font-weight: 500;
}
.ant-carousel {
  color: $texts_2;
}
.ant-carousel .slick-dots li button {
  background: $light_theme;
}
.ant-carousel .slick-dots li.slick-active button {
  background: $light_theme;
  opacity: 1;
}
.ant-cascader {
  color: $texts_2;
}
.ant-cascader-picker {
  color: $texts_2;
  background-color: $light_theme;
}
.ant-cascader-picker-disabled {
  background: $progress_background;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: $buttons_color;
}
.ant-cascader-picker-clear {
  background: $light_theme;
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: $buttons_color;
}
.ant-cascader-menus {
  background: $light_theme;
}
.ant-cascader-menu-item:hover {
  background: $progress_background;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: $background_selected;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: $danger;
}
.ant-input-affix-wrapper {
  color: $texts_2;
  background-color: $light_theme;
  border: 1px solid $borders2;
  border-radius: 4px;
}
.ant-input-affix-wrapper:hover {
  border-color: $buttons_color;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $buttons_color;
}
.ant-input-affix-wrapper-disabled {
  background-color: $progress_background;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: $borders2;
}
.ant-input-affix-wrapper[disabled] {
  background-color: $progress_background;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: $borders2;
}
.ant-input-clear-icon:active {
  color: $texts_2;
}
.ant-input {
  color: $texts_2;
  background-color: $light_theme;
  border: 1px solid $borders2;
  border-radius: 4px;
}
.ant-input:hover {
  border-color: $buttons_color;
}
.ant-input:focus,
.ant-input-focused {
  border-color: $buttons_color;
}
.ant-input-disabled {
  background-color: $progress_background;
}
.ant-input-disabled:hover {
  border-color: $borders2;
}
.ant-input[disabled] {
  background-color: $progress_background;
}
.ant-input[disabled]:hover {
  border-color: $borders2;
}
.ant-input-group {
  color: $texts_2;
}
.ant-input-group-addon {
  color: $texts_2;
  border: 1px solid $borders2;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: $backgrounds;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid $borders2;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-left: 1px solid $borders2;
}
.ant-input-password-icon {
  color: $yellow;
}
.ant-input-password-icon:hover {
  color: $hoverable;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $buttons_color;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: $buttons_color;
}
.ant-input-search-rtl .ant-input {
  border-right: 1px solid $borders2;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: $buttons_color;
  border-left-color: $borders2;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper {
  border-right: 1px solid $borders2;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: $buttons_color;
}
.ant-checkbox {
  color: $texts_2;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: transparent;
}
.ant-checkbox-checked::after {
  border: 1px solid $backgrounds;
}
.ant-checkbox-inner {
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-checkbox-inner::after {
  border: 2px solid $light_theme;
}
.ant-checkbox-checked .ant-chCeckbox-inner::after {
  border: 2px solid $light_theme;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $backgrounds;
  border-color: $backgrounds;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: $dark_hover !important;
  border-color: $borders2 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: $progress_background;
}
.ant-checkbox-wrapper {
  color: $texts_2;
}
.ant-checkbox-group {
  color: $texts_2;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: $light_theme;
  border-color: $borders2;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $backgrounds;
}
.ant-collapse {
  color: $texts_2;
  border: 1px solid $borders2;
  background: none;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid $borders2;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: $texts_2;
}
.ant-collapse-content {
  color: $texts_2;
  background-color: $light_theme;
  border-top: 1px solid $borders2;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid $borders2;
}
.ant-descriptions-title {
  color: $texts_2;
}
.ant-descriptions-extra {
  color: $texts_2;
}
.ant-descriptions-item-label {
  color: $texts_2;
}
.ant-descriptions-item-content {
  color: $texts_2;
}
.ant-divider {
  color: $texts_2;
  border-top: 1px solid $borders;
}
.ant-divider-vertical {
  border-left: 1px solid $borders;
}
.ant-divider-horizontal.ant-divider-with-text {
  color: $texts_2;
  border-top-color: $borders;
}
.ant-divider-plain.ant-divider-with-text {
  color: $texts_2;
}
.ant-drawer-title {
  color: $texts_2;
}
.ant-drawer-content {
  background-color: $light_theme;
}
.ant-drawer-header {
  color: $texts_2;
  background: $light_theme;
}
.ant-drawer-header-no-title {
  color: $texts_2;
  background: $light_theme;
}
.ant-drawer .ant-picker-clear {
  background: $light_theme;
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: $danger;
}
.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  background-color: $light_theme;
}
.ant-form-item-has-warning .ant-input-disabled {
  background-color: $progress_background;
  border-color: $borders2;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled {
  background-color: $progress_background;
  border-color: $borders2;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-borderless) .ant-select-selector {
  background-color: $light_theme;
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: $light_theme;
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: $light_theme;
}
.ant-form-item-has-error .ant-form-item-split {
  color: $danger;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: $light_theme;
  border-color: $danger;
}
.ant-form-item-has-error .ant-input-disabled {
  background-color: $progress_background;
  border-color: $borders2;
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled {
  background-color: $progress_background;
  border-color: $borders2;
}
.ant-form-item-has-error .ant-input-prefix {
  color: $danger;
}
.ant-form-item-has-error .ant-input-group-addon {
  color: $danger;
  border-color: $danger;
}
.ant-form-item-has-error .has-feedback {
  color: $danger;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: $danger;
}
.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  background-color: $light_theme;
  border-color: $danger !important;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: $danger;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: $light_theme;
  border-color: $danger;
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: $light_theme;
  border-color: $danger;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  background-color: $light_theme;
  border-color: $danger;
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: $light_theme;
}
.ant-form-item-has-error .ant-transfer-list {
  border-color: $danger;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: $borders2;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: $buttons_color;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: $buttons_color;
}
.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: $danger !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: $danger;
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: $backgrounds;
}
.ant-form {
  color: $texts_2;
}
.ant-form legend {
  border-bottom: 1px solid $borders2;
}
.ant-form output {
  color: $texts_2;
}
.ant-form-item {
  color: $texts_2;
}
.ant-form-item-label > label {
  color: $texts_2;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: $danger;
}
.ant-image-img-placeholder {
  background-color: $progress_background;
  // background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
}
.ant-image-preview-operations {
  color: $texts_2;
}
.ant-input-number {
  color: $texts_2;
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-input-number:hover {
  border-color: $buttons_color;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: $buttons_color;
}
.ant-input-number-disabled {
  background-color: $dark_hover !important;
}
.ant-input-number-disabled:hover {
  border-color: $borders2;
}
.ant-input-number[disabled] {
  background-color: $progress_background;
}
.ant-input-number[disabled]:hover {
  border-color: $borders2;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: $buttons_color;
}
.ant-input-number:hover {
  border-color: $buttons_color;
}
.ant-input-number-focused {
  border-color: $buttons_color;
}
.ant-input-number-disabled {
  background-color: $progress_background;
}
.ant-input-number-disabled:hover {
  border-color: $borders2;
}
.ant-input-number-handler-wrap {
  background: $light_theme;
  border-left: 1px solid $borders2;
}
.ant-input-number-handler-down {
  border-top: 1px solid $borders2;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  border-right: 1px solid $borders2;
}
.ant-layout {
  background: $main_background;
}
.ant-layout-header {
  color: $texts_2;
  background: $nav;
}
.ant-layout-footer {
  color: $texts_2;
  background: $main_background;
}
.ant-layout-sider {
  background: $nav;
}
.ant-layout-sider-trigger {
  color: $light_theme;
  background: $nav_bottom;
}
.ant-layout-sider-zero-width-trigger {
  color: $light_theme;
  background: $nav;
}
.ant-layout-sider-light {
  background: $light_theme;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: $texts_2;
  background: $light_theme;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: $texts_2;
  background: $light_theme;
}
.ant-list {
  color: $texts_2;
}
.ant-list-item {
  color: $texts_2;
}
.ant-list-item-meta-content {
  color: $texts_2;
}
.ant-list-item-meta-title {
  color: $texts_2;
}
.ant-list-item-meta-title > a {
  color: $texts_2;
}
.ant-list-item-meta-title > a:hover {
  color: $backgrounds;
}
.ant-list-vertical .ant-list-item-meta-title {
  color: $texts_2;
}
.ant-list-bordered {
  border: 1px solid $borders2;
}
.ant-spin {
  color: $texts_2;
  color: $backgrounds;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  text-shadow: 0 1px 2px $light_theme;
}
.ant-spin-container::after {
  background: $light_theme;
}
.ant-spin-dot-item {
  background-color: $backgrounds;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: $light_theme;
  }
}
.ant-pagination {
  color: $texts_2;
}
.ant-pagination-item {
  background-color: $light_theme;
  border: 1px solid $borders2;
  border-radius: 4px;
}
.ant-pagination-item a {
  color: $texts_2;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $backgrounds;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: $backgrounds;
}
.ant-pagination-item-active {
  background: $light_theme;
  border-color: $backgrounds;
}
.ant-pagination-item-active a {
  color: $backgrounds;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: $buttons_color;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: $buttons_color;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $backgrounds;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: $texts_2;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: $texts_2;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: $buttons_color;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: $light_theme;
  border: 1px solid $borders2;
  border-radius: 4px;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $backgrounds;
  border-color: $backgrounds;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: $borders2;
}
.ant-pagination-options-quick-jumper input {
  color: $texts_2;
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: $buttons_color;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: $buttons_color;
}
.ant-pagination-options-quick-jumper input-disabled {
  background-color: $progress_background;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: $borders2;
}
.ant-pagination-options-quick-jumper input[disabled] {
  background-color: $progress_background;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: $borders2;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: $backgrounds;
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  background: $progress_background;
  border-color: $borders2;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: $progress_background;
  border-color: $borders2;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: $light_theme;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: $progress_background;
  border-color: $borders2;
}
.ant-mentions {
  color: $texts_2;
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-mentions:hover {
  border-color: $buttons_color;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: $buttons_color;
}
.ant-mentions-disabled {
  background-color: $progress_background;
}
.ant-mentions-disabled:hover {
  border-color: $borders2;
}
.ant-mentions[disabled] {
  background-color: $progress_background;
}
.ant-mentions[disabled]:hover {
  border-color: $borders2;
}
.ant-mentions-disabled > textarea {
  background-color: $progress_background;
}
.ant-mentions-disabled > textarea:hover {
  border-color: $borders2;
}
.ant-mentions-focused {
  border-color: $buttons_color;
}
.ant-mentions-dropdown {
  color: $texts_2;
  background-color: $light_theme;
}
.ant-mentions-dropdown-menu-item {
  color: $texts_2;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: $progress_background;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  background-color: $light_theme;
}
.ant-mentions-dropdown-menu-item-selected {
  color: $texts_2;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: $progress_background;
}
.ant-message {
  color: $texts_2;
}
.ant-message-notice-content {
  background: $light_theme;
}
.ant-message-error .anticon {
  color: $danger;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: $backgrounds;
}
.ant-modal {
  color: $texts_2;
}
.ant-modal-title {
  color: $texts_2;
}
.ant-modal-content {
  background-color: $light_theme;
  border-radius: 4px;
}
.ant-modal-header {
  color: $texts_2;
  background: $light_theme;
  border-bottom: 1px solid $borders;
}
.ant-modal-footer {
  border-top: 1px solid $borders;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: $texts_2;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: $texts_2;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: $danger;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: $backgrounds;
}
.ant-notification {
  color: $texts_2;
}
.ant-notification-hook-holder,
.ant-notification-notice {
  background: $light_theme;
}
.ant-notification-notice-message {
  color: $texts_2;
}
.anticon.ant-notification-notice-icon-info {
  color: $backgrounds;
}
.anticon.ant-notification-notice-icon-error {
  color: $danger;
}
.ant-page-header {
  color: $texts_2;
  background-color: $light_theme;
}
.ant-page-header-back-button {
  color: $texts_2;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: $buttons_color;
}
.ant-page-header-back-button:active {
  color: $active;
}
.ant-page-header-heading-title {
  color: $texts_2;
}
.ant-progress {
  color: $texts_2;
}
.ant-progress-steps-item-active {
  background: $backgrounds;
}
.ant-progress-inner {
  background-color: $progress_background;
}
.ant-progress-circle-trail {
  stroke: $progress_background;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: $backgrounds;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: $backgrounds;
}
.ant-progress-status-active .ant-progress-bg::before {
  background: $light_theme;
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: $danger;
}
.ant-progress-status-exception .ant-progress-text {
  color: $danger;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: $danger;
}
.ant-progress-circle .ant-progress-text {
  color: $texts_2;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: $danger;
}
.ant-rate {
  color: $texts_2;
}
.ant-result-error .ant-result-icon > .anticon {
  color: $danger;
}
.ant-result-info .ant-result-icon > .anticon {
  color: $backgrounds;
}
.ant-result-title {
  color: $texts_2;
}
.ant-skeleton-header .ant-skeleton-avatar {
  background: $hover_color_table;
}
.ant-skeleton-content .ant-skeleton-title {
  background: $hover_color_table;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  background: $hover_color_table;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, $hover_color_table), color-stop(37%, #e6e6e6), color-stop(63%, $hover_color_table));
  background: linear-gradient(90deg, $hover_color_table 25%, #e6e6e6 37%, $hover_color_table 63%);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, $hover_color_table), color-stop(37%, #e6e6e6), color-stop(63%, $hover_color_table));
  background: linear-gradient(90deg, $hover_color_table 25%, #e6e6e6 37%, $hover_color_table 63%);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, $hover_color_table), color-stop(37%, #e6e6e6), color-stop(63%, $hover_color_table));
  background: linear-gradient(90deg, $hover_color_table 25%, #e6e6e6 37%, $hover_color_table 63%);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, $hover_color_table), color-stop(37%, #e6e6e6), color-stop(63%, $hover_color_table));
  background: linear-gradient(90deg, $hover_color_table 25%, #e6e6e6 37%, $hover_color_table 63%);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, $hover_color_table), color-stop(37%, #e6e6e6), color-stop(63%, $hover_color_table));
  background: linear-gradient(90deg, $hover_color_table 25%, #e6e6e6 37%, $hover_color_table 63%);
}
.ant-skeleton-element .ant-skeleton-button {
  background: $hover_color_table;
}
.ant-skeleton-element .ant-skeleton-avatar {
  background: $hover_color_table;
}
.ant-skeleton-element .ant-skeleton-input {
  background: $hover_color_table;
}
.ant-skeleton-element .ant-skeleton-image {
  background: $hover_color_table;
}
.ant-slider {
  color: $texts_2;
}
.ant-slider-rail {
  background-color: $progress_background;
}
.ant-slider-handle {
  background-color: $light_theme;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: $backgrounds;
}
.ant-slider-mark-text-active {
  color: $texts_2;
}
.ant-slider-dot {
  background-color: $light_theme;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: $light_theme;
}
.ant-statistic {
  color: $texts_2;
}
.ant-statistic-content {
  color: $texts_2;
}
.ant-steps {
  color: $texts_2;
}
.ant-steps-item-icon .ant-steps-icon {
  color: $backgrounds;
}
.ant-steps-item-title {
  color: $texts_2;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: $light_theme;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: $light_theme;
  border-color: $backgrounds;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: $backgrounds;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $backgrounds;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: $texts_2;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: $texts_2;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: $backgrounds;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: $light_theme;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: $light_theme;
  border-color: $backgrounds;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $backgrounds;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $backgrounds;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: $texts_2;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $backgrounds;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $backgrounds;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: $light_theme;
  border-color: $danger;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: $danger;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $danger;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: $danger;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: $danger;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: $danger;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: $backgrounds;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: $backgrounds;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: $backgrounds;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: $backgrounds;
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: $backgrounds;
}
.ant-switch {
  color: $texts_2;
}
.ant-switch-checked {
  background-color: $backgrounds;
}
.ant-switch-inner {
  color: $light_theme;
}
.ant-switch-handle::before {
  background-color: $light_theme;
}
.ant-switch-loading-icon {
  color: $texts;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: $backgrounds;
}
.ant-table {
  color: $texts_2;
  background: $light_theme;
}
.ant-table-footer {
  color: $texts_2;
}
.ant-table-thead > tr > th {
  color: $texts_2;
  background: $modal_footers_background;
  border-bottom: 1px solid $borders;
}
.ant-table-tbody {
  > tr > td {
    border-bottom: 1px solid $borders;
  }
  .ant-table-expanded-row > td {
    border-bottom-width: 3px !important;
  }
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: $background_selected;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  color: $hover_color_table;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $backgrounds;
}
.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: $mantis;
  color: $gray;
}
.ant-table-filter-trigger.active {
  color: $backgrounds;
}
.ant-table-filter-dropdown {
  color: $texts_2;
  background-color: $light_theme;
}
.ant-table-row-expand-icon {
  color: $texts_2;
  background: $light_theme;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: $buttons_color;
}
.ant-table-row-expand-icon:active {
  color: $active;
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: $light_theme;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: $light_theme;
}
.ant-table-sticky-scroll {
  background: $light_theme;
}
.ant-timeline {
  color: $texts_2;
}
.ant-timeline-item-head {
  background-color: $light_theme;
}
.ant-timeline-item-head-blue {
  color: $backgrounds;
  border-color: $backgrounds;
}
.ant-timeline-item-head-red {
  color: $danger;
  border-color: $danger;
}
.ant-transfer {
  color: $texts_2;
}
.ant-transfer-disabled .ant-transfer-list {
  background: $progress_background;
}
.ant-transfer-list {
  border: 1px solid $borders2;
}
.ant-transfer-list-header {
  color: $texts_2;
  background: $light_theme;
}
.ant-transfer-list-content-item-remove {
  color: $backgrounds;
  color: $borders2;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: $buttons_color;
}
.ant-transfer-list-content-item-remove:active {
  color: $active;
}
.ant-transfer-list-content-item-remove:hover {
  color: $buttons_color;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: $progress_background;
}
.ant-transfer-list-content-item-checked {
  background-color: $background_selected;
}
.ant-select-tree-checkbox {
  color: $texts_2;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: $backgrounds;
}
.ant-select-tree-checkbox-checked::after {
  border: 1px solid $backgrounds;
}
.ant-select-tree-checkbox-inner {
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-select-tree-checkbox-inner::after {
  border: 2px solid $light_theme;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border: 2px solid $light_theme;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: $backgrounds;
  border-color: $backgrounds;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: $progress_background;
  border-color: $borders2 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: $progress_background;
}
.ant-select-tree-checkbox-wrapper {
  color: $texts_2;
}
.ant-select-tree-checkbox-group {
  color: $texts_2;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: $light_theme;
  border-color: $borders2;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: $backgrounds;
}
.ant-select-tree {
  color: $texts_2;
  background: $light_theme;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: $background_selected;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: $progress_background;
}
.ant-select-tree .ant-select-tree-switcher-loading-icon {
  color: $backgrounds;
}
.ant-select-tree .ant-select-tree-switcher-leaf-line::before {
  border-left: 1px solid $borders2;
}
.ant-select-tree .ant-select-tree-switcher-leaf-line::after {
  border-bottom: 1px solid $borders2;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: $progress_background;
}
.ant-select-tree .ant-select-tree-treenode.drag-over > [draggable] {
  color: white;
  background-color: $backgrounds;
  opacity: 0.8;
}
.ant-select-tree .ant-select-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: $backgrounds;
}
.ant-select-tree .ant-select-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: $backgrounds;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit:first-child::after {
  border-right: 1px solid $borders2;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  border-right: 1px solid $borders2;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: $light_theme;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: $progress_background;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: $light_theme;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: $backgrounds;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: $light_theme;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: $light_theme;
}
.ant-tree-checkbox {
  color: $texts_2;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: $backgrounds;
}
.ant-tree-checkbox-checked::after {
  border: 1px solid $backgrounds;
}
.ant-tree-checkbox-inner {
  background-color: $light_theme;
  border: 1px solid $borders2;
}
.ant-tree-checkbox-inner::after {
  border: 2px solid $light_theme;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border: 2px solid $light_theme;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: $backgrounds;
  border-color: $backgrounds;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: $progress_background;
  border-color: $borders2 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: $progress_background;
}
.ant-tree-checkbox-wrapper {
  color: $texts_2;
}
.ant-tree-checkbox-group {
  color: $texts_2;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: $light_theme;
  border-color: $borders2;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: $backgrounds;
}
.ant-tree {
  color: $texts_2;
  background: $light_theme;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: $background_selected;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: $progress_background;
}
.ant-tree .ant-tree-switcher-loading-icon {
  color: $backgrounds;
}
.ant-tree .ant-tree-switcher-leaf-line::before {
  border-left: 1px solid $borders2;
}
.ant-tree .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid $borders2;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: $progress_background;
}
.ant-tree .ant-tree-treenode.drag-over > [draggable] {
  background-color: $backgrounds;
}
.ant-tree .ant-tree-treenode.drag-over-gap-top > [draggable] {
  border-top-color: $backgrounds;
}
.ant-tree .ant-tree-treenode.drag-over-gap-bottom > [draggable] {
  border-bottom-color: $backgrounds;
}
.ant-tree-show-line .ant-tree-indent-unit:first-child::after {
  border-right: 1px solid $borders2;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  border-right: 1px solid $borders2;
}
.ant-tree-show-line .ant-tree-switcher {
  background: $light_theme;
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  border-left: 1px solid $borders2;
}
.ant-typography {
  color: $texts_2;
}
.ant-typography.ant-typography-danger {
  color: $danger;
}
h1.ant-typography,
.ant-typography h1 {
  color: $texts_2;
}
h2.ant-typography,
.ant-typography h2 {
  color: $texts_2;
}
h3.ant-typography,
.ant-typography h3 {
  color: $texts_2;
}
h4.ant-typography,
.ant-typography h4 {
  color: $texts_2;
}
h5.ant-typography,
.ant-typography h5 {
  color: $texts_2;
}
a.ant-typography,
.ant-typography a {
  color: $backgrounds;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: $buttons_color;
}
a.ant-typography:active,
.ant-typography a:active {
  color: $active;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: $backgrounds;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: $buttons_color;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: $active;
}
.ant-upload {
  color: $texts_2;
}
.ant-upload.ant-upload-select-picture-card {
  border: 1px dashed $borders2;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: $backgrounds;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: $borders2;
}
.ant-upload.ant-upload-drag {
  border: 1px dashed $borders2;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: $active;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $buttons_color;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $buttons_color;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  color: $texts_2;
}
.ant-upload-list {
  color: $texts_2;
}
.ant-upload-list-item .anticon-close:hover {
  color: $texts_2;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: $progress_background;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: $danger;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: $danger;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid $borders2;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: $danger;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='$background_selected'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='$background_selected'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='$backgrounds'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='$backgrounds'] {
  fill: $danger;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: $light_theme;
}
.ant-menu-submenu .ant-menu-item .anticon {
  margin-right: 0;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: none;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: none;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: none;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: none;
}
// repair focus of password form fields
.ant-input-affix-wrapper:focus-within {
  box-shadow: 0 0 0 1px #eae8ff !important;
}
.ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none !important;
}
// remove unnecessary small table background
.ant-table-small .ant-table-thead > tr > th {
  background: none;
}
// remove line on last element in collapse
.ant-collapse > .ant-collapse-item:last-child {
  border-bottom: none;
}
// remove line on last element in table
.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}
// add borders to small table
.ant-table-small {
  border: 1px solid $borders;
}
.ant-radio-group .ant-radio-button-wrapper:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-radio-group .ant-radio-button-wrapper:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
